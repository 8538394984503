/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";

export function RecentPepole({ className, type, pepole }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Recent {type}
          </h3>
        </div>
        {pepole &&
          pepole.map(person => (
            <div key={person.id} className="card-body p-1 pl-8">
              <div className="d-flex align-items-center mb-3">
                <div className="d-flex flex-column font-weight-bold">
                  <a
                    href="#"
                    className="text-dark text-hover-primary mb-1 font-size-lg"
                  >
                    {person.name}
                  </a>
                  <span className="text-muted">{person.email}</span>
                </div>
              </div>
            </div>
          ))}

        {/* Body */}
      </div>
    </>
  );
}
